<template>
  <v-hover v-slot:default="{ hover }">
    <v-navigation-drawer
      v-model="navDrawer"
      v-resize="onResize"
      class="main-menu"
      width="200"
      color="primary"
      mini-variant-width="80"
      :mini-variant="!hover"
      :clipped="clipped"
      fixed
      app
      dark
      permanent
      @input="input">
      <v-toolbar
        flat
        color="primary"
        height="80px">
        <v-list-item three-line class="primary">
          <v-list-item-avatar
            tile
            size="40"
            color="grey" />
          <v-list-item-content>
            <v-list-item-title class="body-1 mb-1">
              {{ companyName }}
            </v-list-item-title>
            <v-list-item-subtitle class="body-2">
              {{ getUserInfo.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar>

      <v-divider />

      <v-list class="menu-list justify-center py-0" dense>
        <div class="menu-items-wrapper">
          <v-list-item
            exact
            :to="{ name: 'Home' }">
            <template>
              <v-list-item-action>
                <v-icon>{{ $root.icons.link.home }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Dashboard
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item
            v-for="menu in getMenu"
            :key="menu.title"
            :to="{name: menu.name, params: menu.params}"
            link>
            <template>
              <v-list-item-action>
                <font-awesome-icon
                  v-if="menu.name === 'Negotiations'"
                  :icon="menu.icon" />
                <v-icon v-else>
                  {{ menu.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ menu.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </div>

        <v-footer :class="hover && 'px-0'" color="primary" fixed>
          <v-list-item
            v-if="showConfig"
            class="config-button"
            :to="{ name: 'Configuration' }">
            <v-list-item-action>
              <v-icon>{{ $root.icons.link.settings }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="hover">
                Configurações
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content v-if="hover">
              <v-list-item-title>Sair da conta</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-footer>
      </v-list>
    </v-navigation-drawer>
  </v-hover>
</template>

<script>
  import { logout } from '@/utils'
  import { mapGetters } from 'vuex'

  export default {
    name: 'MainMenu',
    mixins: [ logout ],
    props: {
      drawer: {
        default: true,
        type: Boolean
      }
    },
    data () {
      return {
        title: 'Modera',
        clipped: false,
        navDrawer: this.drawer,
        showConfig: true
      }
    },
    computed: {
      ...mapGetters('menu', ['getMenu']),
      ...mapGetters('global', ['getMiniLogo']),
      ...mapGetters('user', ['getUserInfo']),
      ...mapGetters({
        companyName: 'currentCompany/name'
      })
    },
    watch: {
      drawer (value) {
        this.navDrawer = value
      },
      windowSize (size) {
        this.showConfig = this.$can('Configuration')
      }
    },
    mounted () {
      this.onResize()
    },
    methods: {
      input (value) {
        this.$emit('changeValue', value)
      },
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      }
    }
  }
</script>

<style lang="sass">
  .v-navigation-drawer.main-menu
    z-index: 10

    .v-navigation-drawer__content
      overflow-y: hidden

  .main-menu
    .v-toolbar
      display: flex
      justify-content: center

    .v-toolbar__content
      padding: 0
      position: fixed
      background: #fff
      z-index: 10

  .menu-list
    height: calc(100vh - 51px)
    display: flex
    flex-direction: column
    justify-content: space-between
    // overflow-y: scroll

    .v-list-item
      width: 100%
      padding: 0 16px
      height: 48px
      flex: 0

    span
      display: block
      text-align: center
      width: 100%

    // .v-list-item
    //   .svg-inline--fa
    //       color: #757575
    //       font-size: 20px

    //   &--active
    //     background-color: $gray

    //     .v-icon
    //       color: $accent
</style>
