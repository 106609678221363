<template>
  <not-authenticated>
    <v-card
      class="mx-auto login-area-box px-10"
      flat>
      <v-card-title>
        <h1 class="headline font-weight-medium text-center">
          Acessar plataforma
        </h1>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-form
          ref="formLogin"
          v-model="validLogin"
          lazy-validation>
          <v-text-field
            v-model="user.email"
            name="mlogin"
            label="Nome de usuário"
            type="email"
            data-cy="login-user"
            autofocus
            filled
            :rules="emailRules"
            required />
          <v-text-field
            v-model="user.password"
            name="mpass"
            label="Senha"
            data-cy="login-pass"
            :rules="requiredRules"
            :append-icon="hidePass ? icons.state.show_pass : icons.state.hide_pass"
            :type="hidePass ? 'password' : 'text'"
            required
            filled
            @keyup.enter="loginSubmit"
            @click:append="() => (hidePass = !hidePass)" />
        </v-form>
        <v-card-actions class="px-2">
          <v-spacer />
          <router-link
            class="mediumGray--text caption"
            :to="{ name: 'ForgotPassword' }">
            Esqueci minha senha
          </router-link>
        </v-card-actions>
      </v-card-text>
      <v-card-actions class="px-3 pb-4">
        <v-btn
          class="mx-auto"
          color="primary"
          data-cy="login-btn"
          :loading="isLoading"
          :disabled="!validLogin"
          depressed
          @click="loginSubmit">
          Entrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </not-authenticated>
</template>

<script>
  import NotAuthenticated from '@/components/NotAuthenticated'
  import validate from '@/mixins/validate'
  import { mapGetters } from 'vuex'
  // import Raven from 'raven-js'

  export default {
    components: {
      NotAuthenticated
    },
    mixins: [ validate ],
    data () {
      return {
        user: {
          email: '',
          password: ''
        },
        hidePass: true,
        password: 'Password',
        validLogin: true,
        validInvited: true,
        validPass: true,
        icons: this.$root.icons
      }
    },
    computed: {
      ...mapGetters({
        isLoading: 'app/getLoading'
      })
    },
    beforeMount () {
      this.$store.commit('app/setLoading', false)
    },
    methods: {
      loginSubmit () {
        if (this.$refs.formLogin.validate()) {
          this.$root.$emit('snackbar', {show: false})
          this.validLogin = !this.isLoading
          this.login()
        }
      },
      login () {
        const email = this.user.email
        const password = this.user.password

        this.$store
          .dispatch('app/userLogin', {email, password})
          .catch(this._failure)

      },
      _failure (message) {
        if (['auth/wrong-password', 'auth/user-not-found'].includes(message.code)) {
          this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Usuário/Senha não conferem'})
        } else {
          this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Erro no Login, confira seus dados'})
        }
      },
      _setUserToRaven (user) {
      // Confirmar se o groups está passando
      // Raven.setUserContext({
      //   email: user.email,
      //   id: user.id,
      //   groups: user.groups
      // })
      }
    }
  }
</script>
