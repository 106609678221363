<template>
  <widget-box
    title="Status dos Casos"
    :is-title-center="true"
    @screen.once="showElement">
    <v-layout
      row
      wrap>
      <loading
        v-if="loading > 0"
        class="absolute-center" />

      <v-flex
        v-else
        md12
        class="widget-legend px-5 py-6">
        <div v-if="hasData">
          <apexcharts
            width="100%"
            height="209"
            type="bar"
            :options="chartOptions"
            :series="series"
            class="legend-multiple-lines" />
        </div>
        <empty-state
          v-else
          size="75px"
          :icon="$root.icons.layout.empty_chart"
          class="fixed-center">
          No momento não há dados
        </empty-state>
      </v-flex>
    </v-layout>
  </widget-box>
</template>

<script>
  import widgetsMixin from '@/mixins/widgets'
  import { mapGetters } from 'vuex'

  export default {
    mixins: [ widgetsMixin ],
    data () {
      return {
        loading: 0,
        series: [
          {
            name: 'Aceito',
            data: [40]
          },
          {
            name: 'Baixados',
            data: [10]
          },
          {
            name: 'Recusados',
            data: [5]
          },
          {
            name: 'Baixados',
            data: [45]
          }
        ],
        chartOptions: {
          chart: {
            height: '100%',
            type: 'bar',
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '90%',
              // endingShape: 'rounded',
              dataLabels: {
                position: 'center', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%"
            },
            offsetY: -10,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          legend: {
            position: 'top',
            offsetY: 40
          },
          xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr"],
            labels: {
              show: false
            },
            position: 'top',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%"
              }
            }
          },
          grid: {
            show: false
          }
        },
        // options: {
        //   chart: {
        //     height: 350,
        //     type: 'bar',
        //   },
        //   // plotOptions: { bar: { barHeight: 70 } },
        //   plotOptions: {
        //     bar: {
        //       dataLabels: {
        //         position: 'top', // top, center, bottom
        //       },
        //     }
        //   },
        //   dataLabels: {
        //     enabled: true,
        //     formatter: function (val) {
        //       return val + "%"
        //     },
        //     offsetY: -10,
        //     style: {
        //       fontSize: '12px',
        //       colors: ["#304758"]
        //     }
        //   },
        //   xaxis: {
        //     categories: ['1', '2', '3', '4'],
        //     position: 'top',
        //     axisBorder: {
        //       show: false
        //     },
        //     axisTicks: {
        //       show: false
        //     },
        //     crosshairs: {
        //       fill: {
        //         type: 'gradient',
        //         gradient: {
        //           colorFrom: '#D8E3F0',
        //           colorTo: '#BED1E6',
        //           stops: [0, 100],
        //           opacityFrom: 0.4,
        //           opacityTo: 0.5,
        //         }
        //       }
        //     },
        //     tooltip: {
        //       enabled: true,
        //     }
        //   },
        //   yaxis: {
        //     axisBorder: {
        //       show: false
        //     },
        //     axisTicks: {
        //       show: false,
        //     },
        //     labels: {
        //       show: false,
        //       formatter: function (val) {
        //         return val + "%"
        //       }
        //     }
        //   },
        //   // grid: { padding: { top: 0, right: 0, bottom: -40, left: 0 } },
        //   legend: {
        //     offsetY: 15,
        //     itemMargin: { vertical: 10 }
        //   },
        //   responsive: [{
        //     options: {
        //       chart: { height: 200 },
        //       legend: { itemMargin: { vertical: 5, horizontal: 5 } }
        //     }
        //   }]
        // }
      }
    },
    computed: {
      ...mapGetters({
        reportUnprocessed: 'currentCompany/dasboardPanelResults'
      }),
      // series () {
      //   const report = []
      //   const reportUnprocessed = this.reportUnprocessed.clone()
      //   const inProgress = (reportUnprocessed.directNegotiation || 0) + reportUnprocessed.initialProposal + reportUnprocessed.negotiation + reportUnprocessed.mediation + reportUnprocessed.draft
      //   const accepted = reportUnprocessed.accepted + reportUnprocessed.externalAgreement + (reportUnprocessed.directAgreement || 0)
      //   const refused = reportUnprocessed.refused + (reportUnprocessed.directRefused || 0) + (reportUnprocessed.externalRefused || 0)  + (reportUnprocessed.finished || 0)
      //   delete reportUnprocessed.accepted
      //   delete reportUnprocessed.externalAgreement
      //   delete reportUnprocessed.externalRefused
      //   delete reportUnprocessed.finished
      //   delete reportUnprocessed.directAgreement
      //   delete reportUnprocessed.refused
      //   delete reportUnprocessed.directRefused
      //   delete reportUnprocessed.directNegotiation
      //   delete reportUnprocessed.id
      //   delete reportUnprocessed.path
      //   delete reportUnprocessed.initialProposal
      //   delete reportUnprocessed.negotiation
      //   delete reportUnprocessed.mediation
      //   delete reportUnprocessed.draft
      //   delete reportUnprocessed.renegotiated
      //   delete reportUnprocessed.expired
      //   delete reportUnprocessed.suspended
      //   delete reportUnprocessed.totalOriginal
      //   delete reportUnprocessed.acceptedCurrent

      //   report.push({name: this.$t('panelResults.accepted'), data: [accepted]})
      //   for (var prop in reportUnprocessed) {
      //     report.push({name: this.$t('panelResults.' + prop), data: [reportUnprocessed[prop]]})
      //   }
      //   report.push({name: this.$t('panelResults.refused'), data: [refused]})
      //   report.push({name: this.$t('panelResults.inProgress'), data: [inProgress]})
      //   // return report
      //   return [2.3, 3.1, 4.0, 10.1]
      // },
      totalValue () {
        return this.reportUnprocessed.totalOriginal
      },
      totalContract () {
        return this.reportUnprocessed.acceptedCurrent
      }
    },
    beforeMount () {
      const options = this.options
      // const responsive = options.responsive[0]

      // Object.assign(this.barChartOptions.plotOptions.bar, options.plotOptions.bar)
      // Object.assign(this.barChartOptions.xaxis, options.xaxis)
      // Object.assign(this.barChartOptions.grid, options.grid)
      // Object.assign(this.barChartOptions.legend, options.legend)
      // Object.assign(this.barChartOptions.responsive[0].options.chart, responsive.options.chart)
      // Object.assign(this.barChartOptions.responsive[0].options.legend, responsive.options.legend)
    },
    methods: {
      formatLegend (status, value){
        return status
      },
      showElement () {
        this.$store.dispatch('currentCompany/getDashboardPanelResult')
      }
    },
  }
</script>
