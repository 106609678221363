<template>
  <v-layout
    row
    wrap>
    <v-flex
      xs12
      sm8>
      <v-card
        v-if="isMediator"
        class="mb-6">
        <v-layout
          row
          wrap
          class="px-5 py-6">
          <v-flex
            xs12
            sm4
            class="text-center">
            <p class="display-2 mb-2">
              12
            </p>
            <p class="subtitle-1 mb-0">
              Casos em andamento
            </p>
          </v-flex>
          <v-flex
            xs12
            sm4
            class="text-center">
            <p class="display-2 mb-2">
              322
            </p>
            <p class="subtitle-1 mb-0">
              Casos com acordo
            </p>
          </v-flex>
          <v-flex
            xs12
            sm4
            class="text-center">
            <p class="display-2 mb-2">
              91
            </p>
            <p class="subtitle-1 mb-0">
              Casos sem acordo
            </p>
          </v-flex>
        </v-layout>
      </v-card>

      <v-card>
        <v-card-title class="headline">
          Propostas em andamento
        </v-card-title>
        <app-list
          :total-entries="proposals.length"
          :entries="proposals"
          :headers="headers"
          :is-render="isRender"
          :loading-list="loading"
          hide-footer
          class="negotiatior-proposal-list">
          <template v-slot:row="row">
            <row :props="row.props" />
          </template>
        </app-list>
      </v-card>
    </v-flex>

    <v-flex
      xs12
      sm4
      class="pt-0">
      <v-card class="mb-3">
        <v-card-title class="headline">
          Mensagens
        </v-card-title>

        <v-list two-line>
          <template v-for="notification in notifications">
            <message-row
              :key="notification.id"
              :item="notification" />
          </template>
        </v-list>
      </v-card>

      <v-card>
        <v-card-title class="headline">
          Tarefas
        </v-card-title>

        <v-list one-line>
          <template v-for="task in tasks">
            <task-row
              :key="task.id"
              :item="task" />
          </template>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import AppList from '@/components/ui/List'
  import MessageRow from './MessageRow'
  import TaskRow from './TaskRow'
  import Row from './Row'
  import { mapGetters } from 'vuex'

  export default {
    components: { AppList, Row, MessageRow, TaskRow},
    data () {
      return {
        headers: [
          { text: 'Código', value: 'code', sortable: false },
          { text: 'Nome', value: 'recipient_name', sortable: false },
          { text: 'Valor da proposta', value: 'currentValue', sortable: false },
          { text: 'Status', value: 'state', sortable: false },
          { text: 'Expira em', value: 'updated_at', sortable: true }
        ],
        pagination: {
          sortBy: [sessionStorage.getItem('DashProposalPaginationSortBy') || 'updated_at'],
          sortDesc: [JSON.parse(sessionStorage.getItem('DashProposalPaginationDescending') || true)],
          page: parseInt(sessionStorage.getItem('DashProposalPaginationPage') || 1),
          rowsPerPage: parseInt(sessionStorage.getItem('DashProposalPaginationRowsPerPage') || 13),
          rowsPerPageItems: [13, 25, 50, 100]
        }
      }
    },
    computed: {
      ...mapGetters({
        proposals: 'proposal/list',
        loading: 'proposal/listLoading',
        isRender: 'proposal/listRender',
        unreadNotifications: 'notifications/list',
        notificationsLoading: 'notifications/listLoading',
        tasks: 'tasks/list',
        isMediator: 'user/isMediator'
      }),
      notifications () {
        const casePaths = Array.from(new Set(this.unreadNotifications.map(item => item.casePath)))
        const grouped = this.groupBy(this.unreadNotifications, item => item.casePath)
        return casePaths.map(key => {
          const messages = grouped.get(key).sort((a, b) => b.insertedAt.seconds - a.insertedAt.seconds)
          const message = messages[0]
          return { ...message }
        })
      }
    },
    beforeMount () {
      this.$store.dispatch('proposal/getActiveList')
      this.$store.dispatch('notifications/getList')
      this.$store.dispatch('tasks/getList')
    },
    methods: {
      groupBy (list, keyGetter) {
        const map = new Map()
        list.forEach((item) => {
          const key = keyGetter(item)
          const collection = map.get(key)
          if (!collection) {
            map.set(key, [item])
          } else {
            collection.push(item)
          }
        })
        return map
      }
    }
  }
</script>

<style lang="sass">
  .negotiatior-proposal-list
    min-height: 210px
</style>
