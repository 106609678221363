<template>
  <widget-box
    title="Acordos"
    :is-content-small="true"
    @screen.once="showElement">
    <v-layout
      row
      wrap>
      <loading
        v-if="loading > 0"
        class="absolute-center" />
      <v-flex
        v-else
        md12
        class="widget-legend px-5 pt-6 pb-2">
        <!-- <div v-if="hasData"> -->
        <div>
          <apexcharts
            width="100%"
            height="90"
            type="donut"
            :options="chartOptions"
            :series="series"
            class="legend-space-between" />
        </div>
        <!-- <empty-state
          v-else
          size="75px"
          :icon="$root.icons.layout.empty_chart"
          class="fixed-center">
          No momento não há dados
        </empty-state> -->
      </v-flex>
    </v-layout>
  </widget-box>
</template>

<script>
  import widgetsMixin from '@/mixins/widgets'
  import donutChartMixin from '@/mixins/charts/donut'
  import { mapGetters } from 'vuex'

  export default {
    mixins: [ widgetsMixin, donutChartMixin ],
    data () {
      return {
        loading: 0,
        options: {
          xaxis: { categories: ['Acordos'] }
        },
        series: [44, 55],
        chartOptions: {
          chart: {
            type: 'donut'
          },
          // dataLabels: {
          //   enabled: false
          // },
          legend: {
            show: false
          },
          labels: ['aaaa', 'bbb'],
          plotOptions: {
            pie: {
              size: 140,
              expandOnClick: false,
              donut: {
                size: '35%',
                background: 'transparent',
                // labels: {
                //   show: true,
                //   total: {
                //     show: false,
                //     label: 'Envios'
                //   }
                // }
              }
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
      }
    },
    computed: {
      ...mapGetters({
        reportUnprocessed: 'currentCompany/dasboardAccepted'
      }),
      // series () {
      //   const reportUnprocessed = this.reportUnprocessed

      //   return [
      //     {name: 'À Vista', data: [reportUnprocessed.cashPayment]},
      //     {name: 'Parcelados', data: [reportUnprocessed.installmentPayment]}
      //   ]
      // },
      totalValue () {
        const reportUnprocessed = this.reportUnprocessed

        return reportUnprocessed.cashPayment + reportUnprocessed.installmentPayment
      }
    },
    beforeMount () {
      Object.assign(this.donutChartOptions.xaxis, this.options.xaxis)
    },
    methods: {
      showElement () {
        this.$store.dispatch('currentCompany/getDashboardAccepted')
      }
    }
  }
</script>
