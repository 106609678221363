<template>
  <v-layout>
    <v-flex
      xs12
      sm12>
      <v-list-item>
        <v-list-item-action>
          <v-icon>error_outline</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action-text>
          <timeago :datetime="item.startAt" :auto-update="60" />
        </v-list-item-action-text>
      </v-list-item>
      <v-divider class="mx-3" />
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>
