<template>
  <v-layout class="message-item-layout">
    <v-flex
      xs12
      sm12>
      <v-list-item
        class="message-item"
        @click="goTo(item.caseId)">
        <v-list-item-content>
          <v-list-item-title
            class="mb-2">
            <promise-key
              v-if="item.from"
              :promise="item.from"
              promise-key="name" />
          </v-list-item-title>

          <v-list-item-subtitle
            class="text-ellipsis"
            v-html="item.message" />
        </v-list-item-content>

        <v-list-item-action-text class="ml-6">
          <promise-key
            v-if="item.case"
            :promise="item.case"
            promise-key="code" />
          <timeago :datetime="item.insertedAtDate" :auto-update="60" />
        </v-list-item-action-text>
      </v-list-item>

      <v-divider class="mx-3" />
    </v-flex>
  </v-layout>
</template>

<script>
  import PromiseKey from '@/components/ui/PromiseKey'

  export default {
    components: {
      PromiseKey
    },
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data() {
      return { }
    },
    methods: {
      goTo (caseId) {
        this.$router.push({ name: 'CaseChat', params: { caseId } })
      }
    }
  }
</script>

<style lang="sass">
  .message-item-layout
    &:last-child
      .v-divider
        display: none

  .v-list-item.message-item
    height: 64px

  .text-ellipsis p
    width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

</style>
