<template>
  <v-container
    class="px-0 py-0 page-login"
    fluid>
    <v-layout
      row
      wrap
      align-center>
      <v-flex
        xs12
        sm12
        md12
        tag="section"
        class="login-area"
        align-center>
        <h1 class="header display-1">
          Mais Soluções
        </h1>
        <slot />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import validate from '@/mixins/validate'
  import { mapGetters } from 'vuex'

  export default {
    name: 'NotAuthenticated',
    mixins: [ validate ],
    computed: {
      ...mapGetters('global', ['getLogo'])
    }
  }
</script>

<style lang="sass">
  @import '../assets/style/login'
</style>
