<template>
  <v-card color="bgColor elevation-0">
    <main-menu
      :drawer="showMenu"
      @changeValue="updateDrawer" />

    <!-- <v-app-bar
      class="elevation-0 main-toolbar"
      color="primary"
      height="50px"
      clipped-right
      fixed
      app>
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        dark
        @click.stop="toggleMenu" />

      <companies-list data-cy="companyList" />

      <v-spacer />
      <unread-notifications />
      <account-drop-down />
    </v-app-bar> -->

    <v-main class="main-content">
      <v-container class="pa-5" fluid>
        <transition
          name="fade"
          mode="out-in"
          appear>
          <router-view />
          <router-view name="view" />
          <router-view name="configuration" />
        </transition>
      </v-container>
    </v-main>

    <!-- <v-content class="main main-config">
      <v-layout justify-center align-center class="layout-main">
        <transition name="fade" mode="out-in" appear>
          <router-view name="configuration" />
        </transition>
      </v-layout>
    </v-content> -->
  </v-card>
</template>

<script>
  import Login from '@/components/pages/Login'
  import MainMenu from '@/components/MainMenu'
  import UnreadNotifications from '@/components/UnreadNotifications'
  // import Alerts from '@/components/Alerts'
  import Configuration from '@/components/Configuration/Configuration'
  import AccountDropDown from '@/components/AccountDropDown'
  import CompaniesList from '@/components/CompaniesList'

  export default {
    name: 'App',
    components: {
      Login,
      MainMenu,
      UnreadNotifications,
      // Alerts,
      Configuration,
      AccountDropDown,
      CompaniesList
    },
    data () {
      return {
        showMenu: true,
        loading: false,
        showConfig: false
      }
    },
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
      }
    },
    beforeMount () {
      this.showMenu = !this.isMobile
    },
    methods: {
      toggleMenu () {
        this.showMenu = !this.showMenu
      },
      updateDrawer (value) {
        this.showMenu = value
      }
    }
  }
</script>

<style lang="sass">
  @import '../assets/style/global'

  .main-content
    background: #000000
    height: 100vh
    .v-main__wrap
      background: #F7F8FC
      border-radius: 20px 0 0 20px
      overflow: hidden
</style>

