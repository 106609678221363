<template>
  <v-layout
    row
    wrap>
    <v-flex
      xs12
      md3
      sm12>
      <v-card flat min-height="209">
        <v-card-title class="font-weight-bold">
          Total de casos
        </v-card-title>
        <v-card-text class="display-1">
          {{ total }}
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      md9
      sm12>
      <!-- <widget-proposal-results is-currency /> -->
      <v-card flat min-height="209">
        <v-card-title class="font-weight-bold">
          <span>Status dos casos</span>
          <v-spacer />
          <v-chip color="success" :small="!isMobile" :x-small="isMobile">
            Aceitos
          </v-chip>
          <v-chip class="ml-2" color="info" :small="!isMobile" :x-small="isMobile">
            Baixados
          </v-chip>
          <v-chip class="ml-2" color="error" :small="!isMobile" :x-small="isMobile">
            Recusados
          </v-chip>
          <v-chip class="ml-2" color="orange lighten-1 white--text" :small="!isMobile" :x-small="isMobile">
            Finalizados
          </v-chip>
          <v-chip class="ml-2" color="blue-grey lighten-2 white--text" :small="!isMobile" :x-small="isMobile">
            Cancelados
          </v-chip>
          <v-chip class="ml-2" color="warning" :small="!isMobile" :x-small="isMobile">
            Em andamento
          </v-chip>
        </v-card-title>
        <v-card-text class="py-0">
          <div id="chart">
            <ul class="bars">
              <li><div :data-percentage="countAccepted" class="bar success"> <span class="value">{{ countAccepted }}%</span></div></li>
              <li><div :data-percentage="countDraft" class="bar info"> <span class="value">{{ countDraft }}%</span></div></li>
              <li><div :data-percentage="countRefused" class="bar error"> <span class="value">{{ countRefused }}%</span></div></li>
              <li><div :data-percentage="countCanceled" class="bar orange lighten-1"> <span class="value">{{ countCanceled }}%</span></div></li>
              <li><div :data-percentage="countFinished" class="bar blue-grey lighten-2"> <span class="value">{{ countFinished }}%</span></div></li>
              <li><div :data-percentage="countInProgress" class="bar warning"> <span class="value">{{ countInProgress }}%</span></div></li>
            </ul>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex
      xs12
      md3
      sm12>
      <v-card flat min-height="187">
        <v-card-title class="font-weight-bold">
          Acordos
        </v-card-title>
        <v-card-text class="display-1">
          {{ $n((reportUnprocessed.acceptedCurrent || 0) + (reportUnprocessed.externalAgreement || 0), 'currency') }}
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      md3
      sm12>
      <v-card flat min-height="187">
        <v-card-title class="font-weight-bold">
          Recebíveis
        </v-card-title>
        <v-card-text class="display-1">
          {{ $n((reportUnprocessed.totalOriginal - reportUnprocessed.acceptedCurrent - reportUnprocessed.externalAgreement) || 0, 'currency') }}
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      md3
      sm12>
      <v-card flat min-height="187">
        <v-card-title class="font-weight-bold">
          Propostas Visualizadas
        </v-card-title>
        <v-card-text class="display-1">
          ------------
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      md3
      sm12>
      <v-card flat min-height="187">
        <v-card-title class="font-weight-bold">
          Em Negociação
        </v-card-title>
        <v-card-text class="display-1">
          {{ inNegotiation }}
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex
      xs12
      sm6
      px-0
      py-0>
      <v-layout column>
        <v-flex
          xs12
          md12
          sm12>
          <!-- <widget-case-accepted /> -->
        </v-flex>
        <!-- <v-flex
          xs12
          md12
          sm12>
          <widget-case-not-accepted />
        </v-flex> -->
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  import WidgetProposalResults from '@/components/ui/widgets/dashboard/WidgetProposalResults'
  import WidgetCaseAccepted from '@/components/ui/widgets/dashboard/cases-status/WidgetCaseAccepted'
  // import WidgetCaseNotAccepted from '@/components/ui/widgets/dashboard/cases-status/WidgetCaseNotAccepted'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      WidgetProposalResults,
      WidgetCaseAccepted,
      // WidgetCaseNotAccepted
    },
    computed: {
      ...mapGetters({
        reportUnprocessed: 'currentCompany/dasboardPanelResults',
        dasboardCasesCount: 'currentCompany/dasboardCasesCount',
      }),
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
      inNegotiation () {
        const dasboardCasesCount = this.dasboardCasesCount
        return dasboardCasesCount.negotiation
      },
      total () {
        const dasboardCasesCount = this.dasboardCasesCount

        return Object.values(dasboardCasesCount).sum(0)
      },
      countAccepted () {
        const dasboardCasesCount = this.dasboardCasesCount
        const sub = dasboardCasesCount.accepted + dasboardCasesCount.externalAgreement

        return ((sub / this.total) * 100).toFixed(2) || 0
      },
      countRefused () {
        const dasboardCasesCount = this.dasboardCasesCount
        const sub = dasboardCasesCount.refused + dasboardCasesCount.externalRefused + dasboardCasesCount.finished

        return ((sub / this.total) * 100).toFixed(2) || 0
      },
      countDraft () {
        const dasboardCasesCount = this.dasboardCasesCount

        return ((dasboardCasesCount.dropped / this.total) * 100).toFixed(2) || 0
      },
      countInProgress () {
        const total = this.total
        const dasboardCasesCount = this.dasboardCasesCount
        const sub = (dasboardCasesCount.directNegotiation || 0) + dasboardCasesCount.initialProposal + dasboardCasesCount.negotiation + dasboardCasesCount.mediation + dasboardCasesCount.draft

        return ((sub / this.total) * 100).toFixed(2) || 0
      },
      countFinished () {
        const dasboardCasesCount = this.dasboardCasesCount

        return ((dasboardCasesCount.finished / this.total) * 100).toFixed(2) || 0
      },
      countCanceled () {
        const dasboardCasesCount = this.dasboardCasesCount

        return ((dasboardCasesCount.canceled / this.total) * 100).toFixed(2) || 0
      },
    },
    mounted() {
      this.$store.dispatch('currentCompany/getDashboardPanelResult')
      this.$store.dispatch('currentCompany/getDashboardCasesCount').then(res => {
        for(const targets of document.getElementById('chart').getElementsByClassName('bar')) {
          const height = `${targets.dataset.percentage}%`

          this.anime({ targets, height, duration: 500 })
        }
      })
    }
  }
</script>

<style lang="sass">
  .fill-height
    height: 100%

  #chart
    width: 100%
    height: 145px
    display: block

    .bars
      display: inline-block
      width: 100%
      height: 145px
      padding: 0
      margin: 0

      li
        display: inline-block
        width: calc(18% - 76px)
        height: inherit
        margin: 0
        text-align: center
        position: relative
        margin-left: 73px

        &:first-child
          margin-left: 0
        &:last-child
          margin-right: 0

        .bar
          display: block
          width: 100%
          height: 0
          position: absolute
          bottom: 0
          border-radius: 5px 5px 0 0
          .value
            position: relative
            top: -20px

          // &:hover
          //   background: #5AE
          //   cursor: pointer

          //   &:before
          //     color: white
          //     content: attr(data-percentage) '%'
          //     position: relative
          //     bottom: 20px
          // &:before
          //   color: black
          //   content: attr(data-percentage) '%'
          //   position: relative
          //   top: -20px

  @media screen and (max-width: 1264px)
    #chart
      .bars
        li
          width: calc(18% - 51px)
          margin-left: 48px
  @media screen and (max-width: 1024px)
    #chart
      .bars
        li
          width: calc(18% - 51px)
          margin-left: 51px
  @media screen and (max-width: 960px)
    #chart
      .bars
        li
          width: calc(18% - 44px)
          margin-left: 43px
  @media screen and (max-width: 600px)
    #chart
      .bars
        li
          width: calc(18% - 24px)
          margin-left: 25px
          .bar
            .value
              position: absolute
              transform: rotate(270deg)
              transform-origin: 0 0
              left: -4px
              top: -5px
              font-size: 0.7em
              font-weight: 600
</style>
