<template>
  <div class="companies-list">
    <v-autocomplete
      v-if="$can('multiCompany')"
      item-text="fantasyName"
      item-value="id"
      label="Administrando"
      :loading="!!loading"
      :items="companies"
      :value="companyId"
      :menu-props="{contentClass:'dataCy'}"
      :data-cy="dataCy"
      @change="changeCompany" />

    <template v-else>
      <div
        v-if="$can('guest')"
        class="name">
        Modera
      </div>
      <div
        v-else
        tabindex="-1"
        class="input-group px-0 py-0 input-group--focused input-group--dirty input-group--text-field input-group--select">
        <div class="input-group__input">
          <div
            class="input-group__selections"
            style="overflow: hidden;">
            <span class="current-name">{{ companyName }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      dataCy: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapGetters({
        companies: 'companies/list',
        loading: 'companies/listLoading',
        companyId: 'currentCompany/id',
        companyName: 'currentCompany/name',
        canLoadCompanies: 'user/canLoadCompanies'
      })
    },
    beforeMount () {
      const pagination = {
        state: '',
        page: 1,
        rowsPerPage: -1
      }
      this.canLoadCompanies && this.$store.dispatch('companies/getList', pagination)
    },
    methods: {
      changeCompany (companyId) {
        this.$root.$emit('snackbar', {show: false})
        this
          .$store
          .dispatch('user/changeCurrentCompany', companyId)
          .then(this._success)
          .catch(this._failure)
      },
      _success () {
        this.$root.$emit('snackbar', {show: true, color: 'success', text: `Você esta administrando a ${this.companyName}`})
      },
      _failure () {
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      }
    }
  }
</script>

<style lang="sass">
  .companies-list
    position: absolute
    top: 14px
    width: 220px

    .v-autocomplete
      display: flex
      height: inherit
      margin-top: 4px

      .v-label,
      .v-select__slot input,
      .v-icon
        color: #fff !important

      .v-select__slot input,
      .v-icon,
      .current-name
        font-weight: 500

      .v-input__slot::before
        display: none

    .name
      color: #fff
      font-size: 20px
      font-weight: 500
      display: flex
      align-items: center
      height: inherit

    .current-name
      color: #fff !important

  @media screen and (max-width: $min-full)
    .companies-list
      left: 70px

</style>
