<template>
  <v-container
    fluid
    grid-list-lg
    class="mt-0 pa-1">
    <template v-if="$can('Home')">
      <dashboard-negotiator v-if="hasGroup('negotiator')" />
      <dashboard-admin v-else />
    </template>
  </v-container>
</template>

<script>
  import DashboardAdmin from '@/components/Dashboard/Administrator'
  import DashboardNegotiator from '@/components/Dashboard/Negotiator/Negotiator'

  export default {
    components: {
      DashboardAdmin,
      DashboardNegotiator
    },
    methods: {
      hasGroup (group) {
        return this.getGroups.includes(group)
      }
    }
  }
</script>
