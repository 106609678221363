<template>
  <v-menu
    class="mr-2"
    content-class="arrow-top menu-details"
    max-width="360px"
    origin="95% -5px"
    transition="scale-transition"
    :close-on-content-click="true"
    :position-y="50"
    bottom
    absolute>
    <template v-slot:activator="{ on }">
      <v-avatar
        color="primary"
        size="30px"
        v-on="on">
        <user-avatar
          default-img="mm"
          :email="me.email || ''"
          :image="me.photoURL"
          :alt="me.name" />
      </v-avatar>
    </template>
    <v-list>
      <v-list-item class="mt-2">
        <v-list-item-avatar
          size="60"
          class="mr-3">
          <user-avatar
            default-img="mm"
            :email="me.email || ''"
            :image="me.photoURL"
            :alt="me.name" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="darkGray--text"
            v-html="me.name" />
          <v-list-item-subtitle v-html="me.email" />
        </v-list-item-content>
        <v-list-item-action
          class="ml-6">
          <v-btn
            small
            depressed
            color="accent"
            class="capitalize"
            @click="editProfile">
            Meu perfil
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-divider class="mt-3" />

      <v-list-item>
        <v-btn
          v-if="$can('CompaniesConfig')"
          depressed
          small
          title="Minhas configurações"
          class="ml-0 mb-0 capitalize"
          @click="goToConfig">
          <v-icon
            color="darkGray"
            class="mr-1"
            dark>
            {{ $root.icons.link.settings }}
          </v-icon>
          <span class="darkGray--text">Configurações</span>
        </v-btn>
        <v-spacer />
        <v-btn
          depressed
          small
          title="Sair"
          class="mr-0 mb-0 capitalize"
          @click.prevent="logout">
          <span class="darkGray--text">Sair</span>
          <v-icon
            color="darkGray"
            class="ml-1"
            dark>
            {{ $root.icons.action.logout }}
          </v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { logout } from '@/utils'
  import UserAvatar from '@/components/ui/UserAvatar'
  import { mapGetters } from 'vuex'

  export default {
    components: { UserAvatar },
    mixins: [ logout ],
    computed: {
      ...mapGetters({
        me: 'user/getUserInfo',
      })
    },
    methods: {
      goToConfig () {
        let params = {
          routeName: this.$route.name,
          ...this.$route.params
        }
        this.$router.push({ name: 'Configuration', params })
      },
      editProfile () {
        this.$router.push({ name: 'User' })
      }
    },
  }
</script>
