<template>
  <v-menu
    ref="menu"
    v-model="menu"
    transition="scale-transition"
    origin="top right"
    max-width="320px"
    min-width="320px"
    max-height="320px"
    class="area-unread-messages"
    content-class="arrow-top unread-messages-panel"
    nudge-right="59"
    nudge-bottom="25"
    :close-on-content-click="true"
    bottom
    left
    absolute>
    <template v-slot:activator="{ on }">
      <v-badge
        :class="[isMobile ? 'mr-2' : 'mr-4']"
        color="error lighten-1"
        overlap>
        <template
          v-if="totalUnread > 0"
          v-slot:badge>
          <span @click="toggleMenu">
            {{ totalUnread }}
          </span>
        </template>
        <v-icon
          color="white"
          medium
          v-on="on">
          {{ $root.icons.layout.notification }}
        </v-icon>
      </v-badge>
    </template>
    <v-list class="py-0">
      <template>
        <v-container
          class="pa-2 area-unread-messages-container"
          grid-v-list-lg>
          <v-card
            :class="{ 'v-card--empty': notifications.length === 0 }"
            flat>
            <v-list
              v-if="notifications.length > 0"
              class="py-0"
              two-line>
              <perfect-scrollbar :options="options" class="ps-area-unread-messages">
                <template v-for="(item, index) in notifications">
                  <v-divider
                    v-if="index !== 0"
                    :key="index" />
                  <v-list-item
                    :key="item.id"
                    @click="goTo(item)">
                    <v-list-item-content class="item-content-area-unread-messages">
                      <v-badge
                        color="error lighten-1"
                        overlap>
                        <template v-slot:badge>
                          {{ item.unread }}
                        </template>

                        <promise-key
                          v-if="item.from"
                          :promise="item.from"
                          promise-key="name" />

                        <span v-else>Modera</span>

                        <v-list-item-subtitle
                          v-model="item.message"
                          class="mt-2" />
                      </v-badge>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </perfect-scrollbar>
            </v-list>
            <empty-state
              v-else
              size="50px">
              Não há mesagens
            </empty-state>
          </v-card>
        </v-container>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import EmptyState from '@/components/ui/EmptyState'
  import PromiseKey from '@/components/ui/PromiseKey'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      EmptyState,
      PromiseKey
    },
    data () {
      return {
        options: { suppressScrollX: true, wheelPropagation: false },
        menu: false,
        activeTab: 0
      }
    },
    computed: {
      ...mapGetters({
        unreadNotifications: 'notifications/list',
        unreadNotificationsLoading: 'notifications/listLoading',
      }),
      isMobile () {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
      },
      notifications () {
        const casePaths = Array.from(new Set(this.unreadNotifications.map(item => item.casePath)))
        const grouped = this.groupBy(this.unreadNotifications, item => item.casePath)
        return casePaths.map(key => {
          const messages = grouped.get(key).sort((a, b) => b.insertedAt.seconds - a.insertedAt.seconds)
          const message = messages[0]
          return { ...message, unread: messages.length }
        })
      },
      totalUnread () {
        return this.notifications.map(item => item.unread).reduce((total, count) => total + count, 0)
      }
    },
    watch: {
      activeTab (val) {
      // http://soundbible.com/tags-alert.html
      // let path = '/static/notification.mp3'
      // let audio = new Audio(path)
      // audio.play()
      }
    },
    beforeMount () {
      this.$store.dispatch('notifications/getList')
    },
    methods: {
      groupBy (list, keyGetter) {
        const map = new Map()
        list.forEach((item) => {
          const key = keyGetter(item)
          const collection = map.get(key)
          if (!collection) {
            map.set(key, [item])
          } else {
            collection.push(item)
          }
        })
        return map
      },
      goTo (item) {
        this.$emit('closeNotification')
        this.$router.push({ name: 'CaseChat', params: { caseId: item.caseId } })
      },
      toggleMenu () {
        this.menu = !this.menu
      }
    }
  }
</script>

<style lang="sass">
  @import '../assets/style/placeholder'


  .v-badge
    cursor: pointer
    user-select: none

  .item-content-area-unread-messages
    padding: 10px !important

  .ps-area-unread-messages
    height: 230px

  .area-unread-messages
    position: relative

    &-container
      min-height: 176px
      min-width: 140px
      display: flex
      flex-wrap: wrap
      max-height: 250px

      > .v-card
        width: 100%

      > .v-card.v-card--empty
        width: 100%
        margin: auto

    .empty-state
      width: 100%

    .v-badge--left
      .v-badge__badge
        display: none

    .v-list
      max-height: inherit
      // overflow-y: scroll
      // height: inherit

      .v-list-item__subtitle p
        margin-bottom: 0 !important

      .v-badge.v-badge--overlap
        display: flex
        flex-direction: column
        justify-content: center

      .v-badge__badge
        top: -3px
        right: 0

</style>
