<template>
  <tr>
    <td>{{ props.item.code }}</td>
    <td>
      <promise-key
        :promise="props.item.case"
        promise-key="recipient.name" />
    </td>
    <td>{{ $n(props.item.currentValue, 'currency') }}</td>
    <td>{{ $t('case.state.' + props.item.state) }}</td>
    <td>{{ $d(props.item.updatedAt.toDate(), 'long') }}</td>
  </tr>
</template>

<script>
  import PromiseKey from '@/components/ui/PromiseKey'
  export default {
    components: { PromiseKey },
    props: {
      props: Object
    }
  }
</script>
